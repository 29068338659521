/* You can add global styles to this file, and also import other style files */
@import "assets/variables";
@import "material-icons/iconfont/material-icons.scss";

.global-footer {
  background-color: #37444c;
}

.app-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.app-content {
  display: flex;
  flex-direction: row;
  flex: 1;
}

main {
  flex: 1;
}

.dynamic-html {
  h1 {
    font-size: $font-xl;
  }
  h2 {
    font-size: $font-l;
  }
  h3 {
    font-size: $font-m;
  }
  p,
  ul li {
    font-size: $font-s;
  }
  line-height: 23px;
}

.k-grid-container {
  padding: 0 !important;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.mt--1 {
  margin-top: -5px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.pl-0 {
  margin-left: 0 !important;
}

.btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $primary-color !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    background-color: rgba($primary-color, 0.7) !important;
    border-color: rgba($primary-color, 0.7) !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 rgba($primary-color, 0.7) !important;
    }
  }
}

.btn-danger {
  background-color: $danger-color !important;
  border-color: $danger-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($danger-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $danger-color !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    background-color: rgba($danger-color, 0.7) !important;
    border-color: rgba($danger-color, 0.7) !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 rgba($danger-color, 0.7) !important;
    }
  }
}

.nav-tabs {
  border-bottom: 2px solid $primary-color !important;
  margin-top: -1px;
  .nav-link.active {
    border-color: transparent;
  }
}

.nav-link {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: $font-xs;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: $gray-color;
  width: 160px;
  height: 45px;

  &:hover {
    background-color: $primary-color !important;
    border-radius: 5px 5px 0px 0px;
    color: white !important;
  }
  &.active {
    background-color: $primary-color !important;
    border-radius: 5px 5px 0px 0px;
    color: white !important;
  }
}

.btn-danger-outline {
  background-color: white !important;
  border-color: $danger-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */
  color: $danger-color;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($danger-color, 0.5) !important;
    color: $danger-color;
  }

  &:active {
    background-color: unset !important;
    color: $danger-color !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    background-color: rgba(white, 0.7) !important;
    border-color: rgba($danger-color, 0.7) !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 rgba($danger-color, 0.7) !important;
    }
  }
}

.primary-color {
  color: $primary-color !important;
}

.btn-primary-outline {
  background-color: white !important;
  border-color: $primary-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */
  color: $primary-color;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5) !important;
    color: $primary-color;
  }

  &:active {
    background-color: unset !important;
    color: $primary-color !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    background-color: rgba(white, 0.7) !important;
    border-color: rgba($primary-color, 0.7) !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 rgba($primary-color, 0.7) !important;
    }
  }
}

.color-secondary {
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $secondary-color !important;
  }
}

.btn-secondary {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $secondary-color !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    background-color: rgba($secondary-color, 0.7) !important;
    border-color: rgba($secondary-color, 0.7) !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 rgba($secondary-color, 0.7) !important;
    }
  }
}

.icon {
  text-decoration: none !important;
  &:hover,
  &:visited {
    text-decoration: none !important;
  }
}

* {
  font-family: "Open Sans", sans-serif;
}

.font-sans {
  font-family: "Open Sans", sans-serif;
}

.main-content-container {
  //min-width: 600px;
  min-height: 60vh;
}

.h1-heading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-xl;
  line-height: 40px;
}

.subheading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-m;
  line-height: 28px;
}

.control-label {
  font-style: normal;
  font-weight: normal;
  font-size: $font-s;
  line-height: 24px;
  margin-top: 20px;
}

.mt-20px {
  margin-top: 20px;
}

.form-control {
  height: 50px;
}

select.form-control {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.tile-color {
  color: $tile-color !important;
}

.grey-heading {
  color: $gray-color !important;
}

.secondary-color {
  color: $secondary-color;
}

.secondary-link-normal {
  color: $secondary-color;
  text-decoration: none;
  font-style: normal;
}

.secondary-link-normal:hover {
  cursor: pointer;
  color: $secondary-color;
  text-decoration: underline;
}

.secondary-link {
  color: $secondary-color;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: $font-s;
}
.secondary-link:hover {
  cursor: pointer;
  color: $secondary-color;
  text-decoration: underline;
}
.grey-link {
  color: $gray-color;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: $font-s;
}

.grey-link:hover {
  cursor: pointer;
  color: $gray-color;
  text-decoration: underline;
}

.grey-message {
  font-style: normal;
  font-weight: normal;
  font-size: $font-s;
  line-height: 24px;
  color: $gray-color;
}

.ol-no-padding {
  margin-left: -1rem;
}
.no-bullet {
  li {
    margin-left: -2rem;
  }
  list-style: none;
}

.form-group label {
  color: #525252;
  font-family: "Open Sans", sans-serif;
  font-size: $font-s;
  font-weight: 600;
  line-height: 23px;
}

.client-logo-img {
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 90px;
  border: 1px solid black;
}

.label-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.p-relative {
  position: relative;
}
.link-with-image {
  display: flex;
  width: max-content;
}

.close-svg-icon {
  position: absolute;
  right: -30px;
  top: 11px;
}

.link-with-svg {
  display: flex;
  width: max-content;
  span {
    padding-left: 10px;
    padding-top: 2px;
  }
}

label.required {
  color: #f44236;
}

.success-block {
  color: #28a745;
}

input.ng-touched.ng-invalid {
  border-color: red;
}

.bad {
  color: red !important;
  display: block;
}

.hover-hand:hover {
  cursor: pointer;
}

.helptext {
  font-style: italic;
  font-weight: normal;
  font-size: $font-xs;
  line-height: 24px;
  color: $light-gray-color;

  &.red {
    color: $danger-color;
  }
}

.full-height {
  height: max-content;
}

.bg-light {
  background-color: $light-background;
}

.steps-page-heading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-l;
  line-height: 32px;
  color: $primary-color;

  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: $secondary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.steps-page-heading-2 {
  font-style: normal;
  font-weight: bold;
  font-size: $font-xl;
  line-height: 40px;
  color: $tile-color;
}

.p-content {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  ul li {
    margin-left: 1.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: $font-m;
    line-height: 28px;
  }

  a {
    color: $secondary-color;
    text-decoration: none;
    font-style: normal;
  }

  a:hover {
    cursor: pointer;
    color: $secondary-color;
    text-decoration: underline;
  }
}

.row.info-banner {
  background: #ffffff;
  border: 1px solid $tile-color;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  // min-height: 250px;
  min-height: max-content !important;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  ul li {
    margin-left: 1.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: $font-m;
    line-height: 28px;
  }

  a {
    color: $secondary-color;
    text-decoration: none;
    font-style: normal;
  }

  a:hover {
    cursor: pointer;
    color: $secondary-color;
    text-decoration: underline;
  }
}

.heading-3 {
  font-style: normal;
  font-weight: bold;
  font-size: $font-l;
  line-height: 32px;
  color: $tile-color;
}

.heading-3-grey {
  font-style: normal;
  font-weight: bold;
  font-size: $font-l;
  line-height: 32px;
  color: $gray-color;
}

.overlay-fill {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  width: 100%;
  height: 100%;
  /* display: none; */
}

.button-center {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
}

.gray-div {
  width: 250px;
  height: 250px;
  background: $footer-white;
  border-radius: 10px;
}

.image-button {
  background: $footer-white;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  border-radius: 5px;
  width: 160px;
  height: 160px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0;

  app-svg {
    display: flex;
    justify-content: center;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: $font-xs;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.25px;
    color: $primary-color;
  }
}

.distribute-evenly {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.steps-page-heading.gray {
  span {
    color: $gray-color !important;
  }
}

.steps-page-heading.green {
  span {
    color: $secondary-color !important;
  }
}

.steps-page-heading.red {
  span {
    color: $danger-color !important;
  }
}

.no-margin-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.h6-heading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  /* Kroll Dark Grey */
  color: $gray-color;
}

.cursor-notallowed {
  cursor: not-allowed !important;
}

.error-message {
  margin-left: 0px;
  color: white;
  background-color: $danger-color !important;
  //   border: 1px $error-message-border solid;
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  font-size: $font-s-em;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.success-message {
  margin-left: 0px;
  color: black;
  background-color: rgba($secondary-color, 0.5) !important;
  //   border: 1px $error-message-border solid;
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  font-size: $font-s-em;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.info-message {
  margin-left: 0px;
  color: black;
  background-color: rgba(LightGray, 0.5) !important;
  //   border: 1px $error-message-border solid;
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  font-size: $font-s-em;
  margin-bottom: 20px;
  word-wrap: break-word;
}

body {
  display: flex;
  flex-direction: column;
}
article {
  flex: 1;
}

.content {
  padding: 3rem !important;
}

.k-switch-on .k-switch-track {
  border-color: $secondary-color !important;
  background-color: $secondary-color !important;
}
.k-switch-on.k-focus .k-switch-track {
  outline: 2px solid rgba($secondary-color, 0.08) !important;
}

.k-radio:checked,
.k-radio.k-checked {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
}
.k-radio:checked:focus {
  box-shadow: 0 0 0 2px rgba($secondary-color, 0.3) !important;
}

@media only screen and (max-width: 600px) {
  button {
    min-width: 100px !important;
  }
  .content {
    padding: 1.5rem !important;
  }
}

.k-state-active,
.k-state-selected {
  background-color: $secondary-color !important;
}

.k-primary,
.k-dialog-titlebar {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: white !important;
}

.k-item.k-state-selected {
  background-color: $primary-color !important;
}

.k-pager-numbers .k-link {
  color: $secondary-color !important;
}
.k-link.k-state-selected {
  background-color: $primary-color !important;
  color: white !important;
}

.k-i-sort-asc-sm,
.k-i-sort-desc-sm {
  color: $secondary-color !important;
}

.k-textbox::selection {
  color: highlighttext !important;
  background-color: highlight !important;
}

.k-today,
.k-nav-today {
  color: $secondary-color !important;
}

.k-button-solid-primary {
  border-color: $primary-color !important;
  background-color: $primary-color !important;
}

.k-grid-header .k-sort-icon,
.k-time-now,
.k-loader-primary {
  color: $secondary-color !important;
}

.k-grid-header {
  background-color: #fafafa !important;
}

.k-list-item.k-selected {
  background-color: $primary-color !important;
}
.k-dialog-actions {
  button {
    min-width: 180px !important;
  }
}

.k-window {
  border-radius: 5px;
}
.k-dialog-titlebar {
  border-radius: 5px 5px 0 0;
}

.k-grid td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.btn:disabled {
  border-color: transparent !important;
}

.k-loading-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.k-loading-panel-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $light-background;
  opacity: 0.8;
}
.k-loading-panel-wrapper {
  position: relative;
  z-index: 2;
}

.k-loading-panel-text {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}

.k-notification-success {
  border-color: $secondary-color !important;
  background-color: $secondary-color !important;
}

.k-notification {
  font-size: 15px !important;
  padding: 10px;
}

kendo-notification {
  padding-right: 50px;
  padding-left: 50px;
}

.k-sorted {
  background-color: transparent !important;
}

.k-menu-expand-arrow {
  color: $secondary-color;
}

.k-menu-link:active {
  color: $primary-color !important;
  background-color: transparent !important;
}

.k-animation-container {
  margin-right: 0px !important;
}

.k-menu {
  font-size: 1rem !important;
  outline: none;
}

.k-tabstrip-items-wrapper .k-item {
  color: black;
  &:hover,
  &:focus {
    color: $primary-color;
  }
}

.form-control[type=file] {
  line-height: 38px;
}